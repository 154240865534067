import React, { useEffect, useState, useCallback } from 'react';
import {
	CLAIM,
	FOLLOWING,
	FULL_CIRCLE,
	RETRAINER,
	RESET,
	FOSTER,
	ACKNOWLEDGED_RETIREMENT,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	RESET_EXPRESS,
	ROLE_ACKNOWLEDGED_RESET_RETRAINER
} from '../../utils/constants';
import useStyles from './styles';
import ScrollMenuGeneric from '../ScrollMenuGeneric';
import { ScreenClassRender, Container, Row, Col } from 'react-grid-system';
import RetrainMonthly from '../RetrainMonthly';
import RESETManagement from '../RESETManagement';
import AcknowledgedRetirementManagement from '../AcknowledgedRetirementManagement';
import AcknowledgedFosterManagement from '../AcknowledgedFosterManagement';
import SEO from '../seo';
import ClaimedThoroughbredListings from '../ClaimedThoroughbredListings';
import FullCircleListings from '../FullCircleListings';
import { useAuth } from '../../hooks/useAuth';
import AppSyncService from '../../graphql/AppSyncService';
import qs from 'query-string';
import {
	getResetHorses,
	getAcknowledgedRetirementHorses,
	getHorsesByProgram
} from '../../graphql/custom.queries';
import HorseFollowListings from '../HorseFollowListings';
import { HorseManagementKeys } from './../../utils/constants/index';
import ResetExpressManagement from '../RESETExpressManagement';
import { checkUserAdmin } from '../../utils/helpers';
import LoaderSpinner from '../LoaderSpinner';

const HorseManagement = ({ location }) => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const isNonAdmin = !checkUserAdmin(currentUser?.Roles);
	const [selection, setSelection] = useState(
		location?.state?.selection || CLAIM
	);
	const { activeRole } = qs.parse(location.search);
	const [userHasResetHorse, setUserHasResetHorse] = useState(false);
	const [userHasRetirementHorse, setUserHasRetirementHorse] = useState(false);
	const [userHasFosterProviderHorse, setUserHasFosterProviderHorse] = useState(
		false
	);
	const [userHasResetExpressHorse, setUserHasResetExpressHorse] = useState(
		false
	);
	const [isLoading, setIsLoading] = useState(true);

	const onLoadAvailableTabs = useCallback(async () => {
		setIsLoading(true);
		if (
			activeRole === FOSTER &&
			currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_FOSTER_CARER)
		) {
			//@NOTE: This condition is required to set a feature of notification going straight to foster tab.
			setSelection(FOSTER);
		}
		if (
			activeRole === RESET_EXPRESS &&
			currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_RESET_RETRAINER)
		) {
			//@NOTE: This condition is required to set a feature of notification going straight to RESET EXPRESS tab.
			setSelection(RESET_EXPRESS);
		}
		if (currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)) {
			if (!activeRole || activeRole === RETRAINER)
				setSelection(location?.state?.selection || RETRAINER);
			const memberExternalId = { memberExternalId: currentUser?.UserId };
			const result = await AppSyncService.execute(
				getResetHorses,
				memberExternalId
			);
			if (result?.data?.getResetHorses) {
				if (result?.data?.getResetHorses?.length !== 0) {
					setUserHasResetHorse(true);
					if (!activeRole || activeRole === RESET)
						setSelection(location?.state?.selection || RESET);
				}
			}
		}

		if (currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_RETIREMENT_FARM)) {
			if (!currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)) {
				if (!activeRole || activeRole === ACKNOWLEDGED_RETIREMENT)
					setSelection(location?.state?.selection || ACKNOWLEDGED_RETIREMENT);
			}

			const memberExternalId = { memberExternalId: currentUser?.UserId };
			const result = await AppSyncService.execute(
				getAcknowledgedRetirementHorses,
				memberExternalId
			);
			if (result?.data?.getAcknowledgedRetirementHorses) {
				setUserHasRetirementHorse(
					result?.data?.getAcknowledgedRetirementHorses?.length !== 0
				);
			}
		}

		if (currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_RESET_RETRAINER)) {
			if (!currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)) {
				if (!activeRole || activeRole === RESET_EXPRESS)
					setSelection(location?.state?.selection || RESET_EXPRESS);
			}

			if (isNonAdmin) {
				setUserHasResetExpressHorse(true);
			} else {
				const result = await AppSyncService.execute(getHorsesByProgram, {
					memberExternalId: currentUser?.UserId,
					programId: 9
				});
				if (result?.data?.getHorsesByProgram) {
					setUserHasResetExpressHorse(
						result?.data?.getHorsesByProgram?.length !== 0
					);
				}
			}
		}

		if (currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_FOSTER_CARER)) {
			if (!currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)) {
				if (!activeRole || activeRole === FOSTER)
					setSelection(location?.state?.selection || FOSTER);
			}

			if (isNonAdmin) {
				setUserHasFosterProviderHorse(true);
			} else {
				const result = await AppSyncService.execute(getHorsesByProgram, {
					memberExternalId: currentUser?.UserId,
					programId: 3
				});
				if (result?.data?.getHorsesByProgram) {
					setUserHasFosterProviderHorse(
						result?.data?.getHorsesByProgram?.length !== 0
					);
				}
			}
		}
		setIsLoading(false);
	}, [currentUser]);

	useEffect(() => {
		onLoadAvailableTabs();
	}, [onLoadAvailableTabs]);

	const menu = [
		{
			id: HorseManagementKeys.CLAIMED,
			navigation: CLAIM,
			title: 'Claimed',
			show: true
		},
		{
			id: HorseManagementKeys.FULL_CIRCLE,
			navigation: FULL_CIRCLE,
			title: 'Full Circle',
			show: true
		},
		{
			id: HorseManagementKeys.LIST_OF_FOLLOWING_HORSE,
			navigation: FOLLOWING,
			title: 'Following',
			show: true
		},
		{
			id: HorseManagementKeys.RETRAINING,
			navigation: RETRAINER,
			title: 'Retraining',
			show: currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)
		},
		{
			id: HorseManagementKeys.RESET,
			navigation: RESET,
			title: 'RESET',
			show: userHasResetHorse
		},
		{
			id: HorseManagementKeys.RESET_EXPRESS,
			navigation: RESET_EXPRESS,
			title: 'RESET 2',
			show: userHasResetExpressHorse
		},
		{
			id: HorseManagementKeys.ACKNOWLEDGED_FOSTER_PROVIDER,
			navigation: FOSTER,
			title: 'STAR',
			show: userHasFosterProviderHorse
		},
		{
			id: HorseManagementKeys.ACKNOWLEDGED_RETIREMENT,
			navigation: ACKNOWLEDGED_RETIREMENT,
			title: 'Retirement',
			show: userHasRetirementHorse
		}
	];

	const selectedTabRender = (isMobile) => {
		switch (selection) {
			case CLAIM:
				return (
					<Row nogutter>
						<Col xs={12}>
							<ClaimedThoroughbredListings isMobile={isMobile} />
						</Col>
					</Row>
				);
			case FOLLOWING:
				return (
					<Row nogutter>
						<Col>
							<HorseFollowListings />
						</Col>
					</Row>
				);
			case FULL_CIRCLE:
				return (
					<Row nogutter>
						<Col xs={12}>
							<FullCircleListings isMobile={isMobile} />
						</Col>
					</Row>
				);
			case RETRAINER:
				return (
					<Row>
						<Col xs={12}>
							<RetrainMonthly />
						</Col>
					</Row>
				);
			case RESET:
				return (
					<Row nogutter>
						<Col xs={12}>
							<RESETManagement isMobile={isMobile} />
						</Col>
					</Row>
				);
			case ACKNOWLEDGED_RETIREMENT:
				return (
					<Row>
						<Col xs={12}>
							<AcknowledgedRetirementManagement
								isMobile={isMobile}
								memberExternalId={currentUser?.UserId}
							/>
						</Col>
					</Row>
				);
			case FOSTER:
				return (
					<Row>
						<Col xs={12}>
							<AcknowledgedFosterManagement
								isMobile={isMobile}
								memberExternalId={currentUser?.UserId}
							/>
						</Col>
					</Row>
				);
			case RESET_EXPRESS:
				return (
					<Row>
						<Col xs={12}>
							<ResetExpressManagement
								isMobile={isMobile}
								memberExternalId={currentUser?.UserId}
							/>
						</Col>
					</Row>
				);
			default:
				throw `Unexpected Tab Selected in My Stable - ${selection}`;
		}
	};

	const renderedComponent = (isMobile) => {
		return (
			<>
				<SEO title="My Stable" />
				<Container
					className={isMobile ? classes.formContainer : classes.container}
					style={{ marginRight: 0, marginLeft: 0 }}
				>
					{isLoading ? (
						<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
					) : (
						<>
							<div className={classes.tabsContainer}>
								<ScrollMenuGeneric
									menuList={menu}
									selectedSearch={selection}
									onClick={(value) => setSelection(value)}
									customStyles={{
										tabContainer: {
											width: '100%',
											marginBottom: 0,
											borderTopLeftRadius: 4,
											borderTopRightRadius: 4,
											borderBottomLeftRadius: 0,
											borderBottomRightRadius: 0
										}
									}}
								/>
							</div>
							{selectedTabRender(isMobile)}
						</>
					)}
				</Container>
			</>
		);
	};

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? renderedComponent(true)
			: renderedComponent(false);
	};

	return <ScreenClassRender render={page} />;
};
export default HorseManagement;
