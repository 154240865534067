import React from 'react';
import useStyles from './styles';
import { Container } from 'react-grid-system';
import SEO from '../seo';
import AvailableForRetrainingRecord from '../AvailableForRetrainingRecord';
import SelectInputField from '../FormComponents/SelectInputField/index.js';
import { HorseStatus } from '../../utils/constants/index';
import { Typography, Link } from '@material-ui/core';
import LoaderSpinner from '../LoaderSpinner';
import { navigate } from '@reach/router';

const AvailableForRetraining = ({
	listingsData,
	isLoading,
	isRetrainerOnly,
	isRetrainerWithResetExpress,
	isAdmin,
	selectedHorseStatus,
	setSelectedHorseStatus
}) => {
	const classes = useStyles();

	const horseStatusOptions = () => {
		const options =
			isAdmin || isRetrainerWithResetExpress
				? HorseStatus
				: HorseStatus.filter((item) => item.id !== 8);
		return options;
	};
	return (
		<>
			<Container className={classes.pageContainer}>
				<SEO title="Available for Retraining" />
				<div className={classes.selectContainer}>
					<SelectInputField
						id={`available-for-retraining-select-input`}
						label="Horse Status"
						value={selectedHorseStatus}
						options={horseStatusOptions()}
						onChange={(e) => setSelectedHorseStatus(e.target.value)}
					/>
				</div>
				{isLoading ? (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				) : listingsData.filter(
						(selectedData) =>
							selectedData.horseStatusSubtype === parseInt(selectedHorseStatus)
				  ).length > 0 ? (
					listingsData
						.filter(
							(selectedData) =>
								selectedData.horseStatusSubtype ===
								parseInt(selectedHorseStatus)
						)
						.map((listing) => (
							<AvailableForRetrainingRecord
								selectedHorseStatus={selectedHorseStatus}
								key={listing.id}
								data={listing}
								isAdmin={isAdmin}
								isRetrainerOnly={isRetrainerOnly}
								isRetrainerWithResetExpress={isRetrainerWithResetExpress}
							/>
						))
				) : (
					<div className={classes.noListingAvailableContainer}>
						<Typography paragraph>No listing available.</Typography>

						{isRetrainerOnly && selectedHorseStatus != 6 ? (
							<Typography>
								Once a RESET horse has been assigned to you, their Available for
								Retraining listing will be displayed here. If you believe there
								is a mistake, please{' '}
								<Link
									underline="always"
									onClick={() => navigate('/contact-us')}
								>
									Contact Us
								</Link>{' '}
							</Typography>
						) : null}
					</div>
				)}
			</Container>
		</>
	);
};
export default AvailableForRetraining;
